.userStatus {
	width: 100px;
	font-size: 12px;
	border-radius: 20px;
	text-transform: uppercase;
	display: block;
	// margin: auto;
	height: 22px;
	padding: 2px 0;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
}

.userStatus.active {
	background-color: #74a5ef;
	color: #fff !important;
}

.userStatus.inActive {
	color: #827d7d;
	background-color: #e3e3e3;
}

.accStatus {
	width: 100px;
	font-size: 12px;
	border-radius: 20px;
	text-transform: uppercase;
	display: block;
	// margin: auto;
	height: 22px;
	padding: 2px 0;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
}

.accStatus.approved {
	background-color: #43a233;
	color: #fff !important;
}
.accStatus.processing {
	background-color: #e0a43a;
	color: #fff;
}
.accStatus.rejected {
	background-color: #e3e3e3;
	color: #827d7d;
}

.printerIcon.userSide {
	background-color: rgb(227, 227, 227);

	&:hover {
		background-color: #4074f9;
		.iconSize24 {
			color: #fff;
		}
	}
}

.userDetailsSection {
	.printerIcon {
		box-shadow: 0px 0px 4px rgb(0 0 0 / 13%);
		width: 2.1vw;
		height: 2.1vw;
		border-radius: 50%;
		text-align: center;
		padding: 6px;
		svg {
			font-size: 1vw;
		}
	}

	.allReports {
		background-color: #4074f9;
		color: #fff;
		border-radius: 8px;
	}
}

.userProfileCard {
	background-color: #fff;
	border-radius: 16px;
	padding: 30px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	margin-top: 50px;
	&:first-child {
		margin-top: 0px;
	}

	h5 {
		font-size: 1.2vw;
	}

	p {
		font-size: 0.9vw;
	}

	.userProfileImg {
		width: 8vw;
		height: 8vw;
		object-fit: cover;
		border-radius: 50%;
	}

	.statesDashedBrdr {
		border: 1px dashed #a49d9d;
		padding: 15px 20px;
		border-radius: 8px;
		text-align: center;
	}

	.detials {
		.detailsSection {
			padding: 10px 0;
			border-bottom: 1px dashed #d1cece;

			.editIcon {
				background-color: #e7e7e7;
				color: #9a9090;
				font-size: 12px;
				font-weight: 500;
				padding: 4px 14px;
				border-radius: 5px;
			}

			.editIcon.active {
				background-color: #c4d4fc;
				color: #4074f9;
			}
		}

		.detailsDiv {
			margin-top: 20px;
			.userType {
				background-color: rgba(67, 162, 51, 0.2);
				color: #43a233;
				font-size: 12px;
				font-weight: 500;
				padding: 4px 14px;
				border-radius: 5px;
			}

			.accountDetails {
				margin-top: 20px;
				h6 {
					font-size: 15px;
					font-weight: 700;
					margin-bottom: 5px;
				}

				p {
					font-size: 13px;
					font-weight: 400;
				}
			}
		}
	}

	.privacyPolicy {
		padding: 20px;
		width: 100%;
		border: 1px dashed #4074f9;
		background-color: #c4d4fc;
		border-radius: 8px;
		.contentP {
			font-size: 14px;
		}

		.icon50 {
			font-size: 50px;
			color: #4074f9;
		}
	}

	.brokersDetails {
		.broker {
			border-bottom: 1px dashed #d1cece;
			padding: 20px 0;
			&:last-child {
				border-color: transparent;
			}
			.brokerLogo {
				width: 65px;
				height: 65px;
				background-color: #d9d9d9;
				img {
				}
			}

			.brokerName {
				margin-left: 15px;
				h5 {
					font-size: 16px;
					font-weight: 600;
				}

				p {
					font-size: 13px;
					font-weight: 400;
					color: #a7a0a0;
				}
			}
		}
	}

	.saveBttn {
		color: #4074f9;
		background-color: #c4d4fc;
		border: none;
		height: 40px;
		font-weight: 600;
		font-size: 0.9vw;
		width: 9vw;
		border-radius: 8px;
	}

	.addPaymentButton {
		color: #4074f9;
		background-color: #c4d4fc;
		border: none;
		border-radius: 8px;
		height: 38px;
		font-weight: 500;
	}
}

.customTabs {
	.tabsSe {
		display: flex;
		align-items: center;
		li {
			list-style-type: none;
			margin-left: 15px;
			margin-right: 15px;
			cursor: pointer;
			&:first-child {
				margin-left: 0px;
			}

			&:last-child {
				margin-right: 0px;
			}
			span {
				padding: 10px;
				border-bottom: 3px solid transparent;
			}
			span.active {
				border-bottom-color: #4074f9;
			}
		}
	}
}

.paymentTable {
	.paymentTbl {
		.status {
			font-size: 12px;
			padding: 3px 5px;
			width: 100px;
			text-align: center;
			border-radius: 8px;
		}

		.status.success {
			background-color: #d2f5e4;
			color: #43a233;
		}

		.status.pending {
			background-color: #ffdc9e;
			color: #e0a43a;
		}

		.status.progres {
			background-color: #e7e0f8;
			color: #6b3ad3;
		}

		tr {
			td.ant-table-cell {
				color: #a49d9d;
			}
		}
	}
}

.paymentTableDropdown {
	background-color: #e9e9e9;
	border: none;
	border-radius: 8px;
}

.paymentCards {
	padding: 20px 0;
	.cardImg {
		width: 55px;
		height: 55px;
		background-color: #d9d9d9;
	}

	.cardDetails {
		h6 {
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 3px;
			.status {
				font-size: 12px;
				padding: 3px 5px;
				width: 100px;
				text-align: center;
				border-radius: 8px;
				display: inline-block;
				position: absolute;
				right: 0;
				top: -4px;
			}

			.status.primary {
				background-color: #d2f5e4;
				color: #43a233;
			}

			.status.expired {
				background-color: #efcfcf;
				color: #b41818;
			}
		}

		p {
			font-size: 14px;
			color: #a49d9d;
		}
	}

	.cardIcons {
		font-size: 30px;
		color: #a49d9d;
		margin-left: 15px;
		cursor: pointer;

		&:hover {
			color: #4074f9;
		}
	}
}

.invoiceTabs {
	li {
		list-style-type: none;
		margin-left: 15px;
		margin-right: 15px;
		cursor: pointer;

		&:first-child {
			margin-left: 0px;
		}

		&:last-child {
			margin-right: 0px;
		}

		span {
			padding: 10px;
			border-bottom: 3px solid transparent;
		}

		span.active {
			border-bottom-color: #4074f9;
		}
	}
}

.allUserDropdown {
	min-width: 250px !important;
	.ant-dropdown-menu {
		li {
			padding: 0px 10px;
			&:hover {
				background-color: transparent;
			}

			a.maroon {
				color: #cf1010;
			}

			a {
				padding: 10px 20px;
				color: #000;
				width: 100%;
				display: block;
				font-weight: 400;
				border-radius: 8px;
				&:hover {
					color: #4074f9;
					background-color: #d0dcfa;
				}
			}

			.anticon {
				padding: 10px 20px;
			}
		}
	}
}
